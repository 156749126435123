import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedComponent } from './shared.component';
import { HeaderComponent } from './components/header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NlSelectComponent } from './components/nl-select/nl-select.component';
import { CapitalizeCharPipe } from './pipe/capitalize-char.pipe';
import { HighlightTextPipe } from './pipe/highlight-text.pipe';
import { RouterModule } from '@angular/router';
import { OneDigitDecimalDirective } from './directives/one-digit-decimal.directive';
import { DropdownDirective } from './directives/dropdown.directive';
import { StripHtmlPipe } from './pipe/strip-html.pipe';
import { DateAgoPipe } from './pipe/date-ago.pipe';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule,RouterModule,],
    declarations: [
        SharedComponent,
        HeaderComponent,
        NlSelectComponent,
        CapitalizeCharPipe,
        HighlightTextPipe,
		OneDigitDecimalDirective,
		DropdownDirective,
		StripHtmlPipe,
		DateAgoPipe
    ],
    exports: [
        HeaderComponent,
        NlSelectComponent,
        CapitalizeCharPipe,
        HighlightTextPipe,
		OneDigitDecimalDirective,
		DropdownDirective,
		StripHtmlPipe,
		DateAgoPipe

    ],
})
export class SharedModule {}
