import { Directive, ElementRef, HostListener, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
	selector: '[appOneDigitDecimal]',
})
export class OneDigitDecimalDirective {
	private specialKeys: Array<string> = [
		'Backspace',
		'Tab',
		'End',
		'Home',
		'-',
		'ArrowLeft',
		'ArrowRight',
		'Del',
		'Delete',
	];
	constructor(
		@Optional() private control: NgControl,
		private el: ElementRef
	) {}
	@HostListener('keyup', ['$event'])
	onKeyDown(event: KeyboardEvent) {
		let current: string = this.el.nativeElement.value;
		if (this.specialKeys.indexOf(event.key) !== -1) {
			return;
		}

		if (current.includes('.')) {
			let values = current.split('.');
			let value =
				current.length > 1
					? values[0] + '.' + values[1][0]
					: //   (values[1].length > 1
					  // 		? values[1][0] + '' + values[1][1]
					  // 		: values[1][0])
					  values[0];
			this.control?.control?.setValue(value, { emit: false });
		}
	}
}
