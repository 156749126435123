import { Component, Input, Output, EventEmitter, ElementRef, HostListener, SimpleChanges  } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

// declare var alertify: any;
@Component({
  selector: 'nl-select',
  templateUrl: './nl-select.component.html',
  styleUrls: ['./nl-select.component.less']
})
export class NlSelectComponent {
  // Input properties
  @Input() changeValue: any = [];
  @Input() suggestions: any[] = [];
  @Input() placeholder: string = 'Type here..';
  @Input() addTagText: string = 'Add ';
  @Input() typeahead = new Subject<string>();
  @Input() autoSuggest: boolean = true;
  @Input() multiSelect: boolean = false;
  @Input() formGroup!: FormGroup;
  @Input() controlName: any;
  @Input() disabled:boolean = false;
  @Input() selectedValue:any = [];

  // Output events
  @Output() focus: EventEmitter<any> = new EventEmitter<any>();

  @Output() onChangeEvent: EventEmitter<any> = new EventEmitter<any>();

  // Component properties
  typedValue: string = '';
  showSuggestion: boolean = false;
  inputChanged: boolean = false;
  alreadySuggested: boolean = false;
  multiSelectedItems: any = [];

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    if (this.controlName) {
      if (this.multiSelect) {
        console.log(this.formGroup.get(this.controlName)?.value);
        this.multiSelectedItems = this.formGroup.get(this.controlName)?.value || [];
		console.log(this.multiSelectedItems);
      } else {
        if(this.autoSuggest) {
          this.typedValue = this.formGroup.get(this.controlName)?.value?.name;
        }

      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['changeValue']) {
      // console.log('InputValue changed. New Value:', newValue, 'Previous Value:', previousValue);
      console.log(this.formGroup.get(this.controlName)?.value, this.controlName, this.formGroup)
      if (this.multiSelect) {
      this.multiSelectedItems = this.formGroup.get(this.controlName)?.value || [];
	  console.log(this.multiSelectedItems,"aaaaa");
	  if(this.multiSelectedItems.length >0 ){
		this.multiSelectedItems.forEach((item:any)=>{

			item['display'] = item?.name;
			item['value'] = item?.name;
			item['id'] = item?.unique_id || ''
		})
	  }
      } else {
        if(!this.autoSuggest) {
          this.typedValue = this.formGroup.get(this.controlName)?.value;
        }
      }
    }
  }



  onInputChange() {
    // Triggered on input change
    this.focus.emit(true);
    this.inputChanged = true;
    this.typeahead.next(this.typedValue);
    this.showSuggestion = true;
    console.log(this.suggestions);

    // Check if the typed value is already suggested
    if (this.suggestions.find(suggestion => suggestion?.name === this.typedValue)) {
      this.alreadySuggested = true;
    } else {
      this.alreadySuggested = false;
    }

  }


  getDefault() {
    this.focus.emit(true);


    // Triggered to get default suggestions
    if (!this.typedValue) {
      this.typeahead.next(this.typedValue);
      this.showSuggestion = true;
      this.inputChanged = true;
    } else {
      this.onInputChange();
      this.inputChanged = false;
    }

    // Show suggestion if autoSuggest is disabled
    if (!this.autoSuggest) {
      this.showSuggestion = true;
    }
  }

  onSelectSuggestion(event:any, suggestion: any) {
    // Triggered on selecting a suggestion
    if (this.multiSelect) {
        const selectedItem = { display: suggestion?.name, value: suggestion?.name, id:suggestion.unique_id };

        // Check if the value already exists in multiSelectedItems
        const isValueExist = this.multiSelectedItems.some((item :any) => item.display === selectedItem.display);

        if (!isValueExist) {
            this.multiSelectedItems?.push(selectedItem);
            // @ts-ignore: Object is possibly 'null'.
            this.formGroup.get(this.controlName)?.setValue(this.multiSelectedItems);

            const index = this.suggestions.findIndex(item => item && item?.name ===  suggestion?.name);
            if(index!=-1) {
              this.suggestions[index]['selected'] = true;
            }
        } else {
        //   alertify.set('notifier', 'position', 'top-right');
		// 			alertify.notify('Item already added.','success');
        }
    } else {
      this.typedValue = suggestion?.name;
      if(this.autoSuggest) {
        // @ts-ignore: Object is possibly 'null'.
        this.formGroup.get(this.controlName).setValue(suggestion);
      } else {
        // @ts-ignore: Object is possibly 'null'.
        this.formGroup.get(this.controlName).setValue(suggestion?.name);
      }

      this.showSuggestion = false;
      event.stopPropagation();
    }

	this.onChangeEvent.emit(true);
  }

  onAddNewValue() {
    // Triggered on adding a new value
    if (this.multiSelect) {
      const selectedItem = { display: this.typedValue, value: this.typedValue  };
        // Check if the value already exists in multiSelectedItems
        const isValueExist = this.multiSelectedItems?.some((item:any) => item?.display === selectedItem.display);

        if (!isValueExist) {
            this.multiSelectedItems?.push(selectedItem);
            // @ts-ignore: Object is possibly 'null'.
            this.formGroup.get(this.controlName)?.setValue(this.multiSelectedItems);
        } else {
        //   alertify.set('notifier', 'position', 'top-right');
		// 			alertify.notify('Item already added.','success');
        }
        // @ts-ignore: Object is possibly 'null'.
      this.formGroup.get(this.controlName)?.setValue(this.multiSelectedItems);
      this.showSuggestion = false;
      this.typedValue = '';
    } else {
        // @ts-ignore: Object is possibly 'null'.
      this.formGroup.get(this.controlName)?.setValue({ name: this.typedValue });
      this.showSuggestion = false;
    }
  }

  onClearSelectedItem(selectedItem:any) {
    // Triggered on clearing a selected item in multi-select mode
    const index = this.multiSelectedItems?.findIndex((item:any) => item && item?.display === selectedItem);
    if (index !== -1) {
      this.multiSelectedItems?.splice(index, 1);
    }

    const selectIndex = this.suggestions.findIndex(item => item && item?.name ===  selectedItem);
    if(selectIndex!=-1) {
      this.suggestions[selectIndex]['selected'] = false;
    }
	this.onChangeEvent.emit(false);
  }

  onClearSelection() {
    // Triggered on clearing the selection
    this.typedValue = '';
    this.getDefault();
    if(!this.multiSelect) {
        // @ts-ignore: Object is possibly 'null'.
      this.formGroup.get(this.controlName)?.setValue(null);
    }
	this.onChangeEvent.emit(false);
  }

  toggleSuggestion() {
    this.showSuggestion = !this.showSuggestion;
    if(this.showSuggestion){
      this.getDefault();
    }
	this.onChangeEvent.emit(false);
  }

  onBlur(event:any) {
   //  this.showSuggestion = false;
  }

  // Listen for document click events
  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    // Check if the click is outside the component's element
    if (!this.elementRef.nativeElement.contains(event.target)) {
      // Close the suggested list
      this.showSuggestion = false;
    }
  }


}
