import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'highlightText'
})
export class HighlightTextPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, typedValue: string): SafeHtml {
    if (!typedValue || !value) {
      return value;
    }

    const regex = new RegExp(typedValue, 'gi');
    const highlighted = value.replace(regex, match => `<span class="highlight-text">${match}</span>`);
    
    // Use DomSanitizer to mark the HTML as safe
    return this.sanitizer.bypassSecurityTrustHtml(highlighted);
  }

}
