<header  *ngIf="showHeader">
	<div class="header-container">
		<div class="logo">
			<!-- <div class="main-menu-toggle" *ngIf="!!isLoggedIn">
				<img
					src="assets/images/icons/hamburger-menu-gray-64-64.png"
					(click)="collapseMenu()"
				/>
			</div> -->
			<div class="main-menu-toggle hamburger" >
				<img
					src="assets/images/icons/hamburger-menu-gray-64-64.png"
					(click)="collapseSideMenu()"
				/>
			</div>
			<img
				[routerLink]="['/']"
				src="assets/images/header/nextleap_logo_only_transparent.png"
			/>
			<span [routerLink]="['/']">Next Leap</span>
		</div>
		<div class="header-menu"  *ngIf="!isLoggedIn">
			<ul >
				<li   appDropdown class="dropdown">Products</li>
				<div class="dropdown-list">
					<div class="product-category">
						<label>Education</label>
						<span>PAM AI Engine</span>
						<div class="hoz_line"></div>
						<ul>
							<li>Learning</li>
							<li>Preparation</li>
							<li>Assessment</li>
							<li>Interview</li>
						</ul>
					</div>
					<div class="product-category">
						<label>Recruitment</label>
						<span>AVA AI Engine</span>
						<div class="hoz_line"></div>
						<ul>
							<li>Resume Builder</li>
							<li>Interview</li>
						</ul>
					</div>
				</div>
				<li>Pricing</li>
				<li>Collaboration</li>
				<li>Reviews</li>
				<li>Blogs</li>
			</ul>

		</div>
		<div class="header-actions">
			 <div class="header-actions-container auth-actions" *ngIf="isLoggedIn">
				<div class="header-user">
					<div
						appDropdown
						class="header-user-image dropdown default-image"
						*ngIf="!isActualImage"
					>
						<img src="assets/images/icons/user-default-gray-64-64.png" />
					</div>
					<div
						appDropdown
						class="header-user-image dropdown"
						*ngIf="!!isActualImage"
					>
						<img [src]="imagePath" />
					</div>
					<ul class="user-options-list dropdown-list" style="padding-left: 0px;">
						<li class="user-option" data-type="text">
							Hi
						</li>
						<li class="user-option" [routerLink]="['user-persona/list']">Your Personas</li>
						<li class="user-option">Change Languages</li>
						<li class="user-option">Settings</li>
						<li class="user-option" (click)="onLogout()">Logout</li>
					</ul>
				</div>
			</div>
			<div class="header-actions-container" *ngIf="!isLoggedIn">
				<div class="header-actions-container" >
				<button class="btn btn-primary btn-bordered login-register-button" data-type="register" [routerLink]="['/auth']">Get Started</button>
				</div>
				<!-- <button class="btn btn-primary btn-bordered login-register-button" (click)="onRegisterClick();" data-type="register">Get Started</button> -->
			</div>
			<!--<div *ngIf="showGoggleLogin">
				<asl-google-signin-button type='icon' size='medium' style="display: none;"></asl-google-signin-button>
			</div> -->
		</div>
	</div>
	<div class="slide-header-menu" *ngIf="showMenu">
		<ul>
			<li appDropdown class="dropdown">
				Products
				<div class="dropdown-list">
					<div class="product-category" data-type="education">
						<label>Education</label>
						<span>PAM AI Engine</span>
						<div class="hoz_line"></div>
						<ul>
							<li (click)="scrollToElement('learn')">Learning</li>
							<li (click)="scrollToElement('prep')">Preparation</li>
							<!-- <li (click)="scrollToElement('assess')">Assessment <span>launching soon</span></li> -->
							<li (click)="scrollToElement('interview')">Interview <span>launching soon</span></li>
						</ul>
					</div>
					<div class="product-category" data-type="recruitment">
						<label>Recruitment</label>
						<span>AVA AI Engine</span>
						<div class="hoz_line"></div>
						<ul>
							<li (click)="scrollToElement('resume')">Resume Builder</li>
							<li (click)="scrollToElement('interview')">Interview <span>launching soon</span></li>
						</ul>
					</div>
				</div>
			</li>
			<li (click)="scrollToElement('pricing')">Pricing</li>
			<li (click)="scrollToElement('colab')">Collaboration</li>
			<li (click)="scrollToElement('reviews')">Reviews</li>
			<li (click)="scrollToElement('blogs')">Blogs</li>
		</ul>
	</div>
</header>


<!-- <app-modal-box  [(show)]="showPricingPlans"  [closeOnBg]="false">
	<ng-template #headerTemplate>
		<div class="modal-header-title"></div>
	</ng-template>
	<ng-template #bodyTemplate>
	  <app-subscription-plan (closePriceModal)="closePlansModal($event)"></app-subscription-plan>
	</ng-template>
</app-modal-box> -->
