import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeChar'
})
export class CapitalizeCharPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return value;
    }

    return value.replace(/(?:^|\s|\/)(.)/g, match => match.toUpperCase());
  }

}
