<div id="autocomplete-container" [ngClass]="{'disabled': disabled}">
    <!-- Multi-select box for selected items (ngIf is used for conditional rendering) -->


    <!-- Search box section -->
    <div id="search-box" class="search-box">
        <!-- Input for searching -->
        <input id="search-input" class="search-input" [(ngModel)]="typedValue" (input)="onInputChange()"
            (focus)="getDefault()" [readonly]="!autoSuggest" (blur)="onBlur($event)" placeholder="{{placeholder}}">

        <div class="search-icons">
            <!-- Clear all button -->
            <span  id="clear-all-button" class="ng-clear-wrapper" title="Clear all" (click)="onClearSelection()">
                <span *ngIf="typedValue" aria-hidden="true" class="ng-clear">×</span>
            </span>

            <!-- Arrow icon for additional actions -->
            <span id="arrow-icon" class="ng-arrow-wrapper" (click)="toggleSuggestion()">
                <span *ngIf="!showSuggestion" class="ng-arrow"></span>
                <!-- <span *ngIf="!showSuggestion" ><i class="fa fa-caret-down" aria-hidden="true"></i></span> -->
                <span *ngIf="showSuggestion"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
            </span>
        </div>

    </div>

    <div class="suggestion-container" [ngClass]="{'multi-select-container': multiSelect}">
        <!-- Suggestion box section -->
        <div id="suggestion-box" *ngIf="autoSuggest && inputChanged && showSuggestion" class="suggestion-box">
            <!-- Message for typing to search when no input provided -->
            <div id="type-to-search-message" *ngIf="!typedValue" class="suggestion-box-item suggest-type">
                <span class="suggestion-option">Type here to search</span>
            </div>

            <!-- Add new value suggestion when input provided and not already suggested -->
            <div id="add-new-value-suggestion" *ngIf="typedValue && !alreadySuggested" (mousedown)="onAddNewValue()" class="suggestion-box-item suggest-add">
                <span class="suggestion-option">{{addTagText}} "{{typedValue}}"</span>
            </div>
        </div>

        <!-- Suggestions based on user input -->
        <div id="suggestions" *ngIf="suggestions && suggestions.length > 0 && showSuggestion" class="suggestion-box">
            <div *ngFor="let suggestion of suggestions; let i = index" (mousedown)="onSelectSuggestion($event, suggestion)" class="suggestion-box-item"
                 [ngClass]="{'alternate-suggestion': i % 2 !== 0}">
                <!-- Display suggestion with or without highlighting based on autoSuggest setting -->
                <span *ngIf="!autoSuggest" [ngClass]="{'suggestion-selected': suggestion?.name == typedValue}" class="suggestion-option">{{suggestion.name | capitalizeChar }}</span>
                <div *ngIf="suggestion?.selected" class="check-icon"><i class="fa fa-check" aria-hidden="true"></i></div>
                <span *ngIf="autoSuggest" class="suggestion-option" [innerHTML]="suggestion.name | highlightText: typedValue"></span>

            </div>
        </div>
    </div>

    <div id="multi-select-box" class="multi-select-box" *ngIf="multiSelect">
        <div *ngFor="let item of multiSelectedItems" id="multi-select-box-item" class="multi-select-box-item">
            <!-- Display the selected item -->
            <span class="item-text">{{item?.display | capitalizeChar }}</span>
            <!-- Button to clear the selected item -->
            <span class="item-clear" (click)="onClearSelectedItem(item?.display)">x</span>
        </div>
    </div>
</div>
