import { Component, OnInit } from '@angular/core';
import {
	NavigationEnd,
	NavigationError,
	NavigationStart,
	Router,
} from '@angular/router';
import { AuthService } from '../../../core/modules/auth/services/auth.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.less'],
})
export class HeaderComponent implements OnInit {
	isLoggedIn: boolean = false;
	isActualImage: boolean = false;
	showMenu: boolean = false;
	showHeader: boolean = true;
	listOfAllowedPath = [
		'/user-persona/institute-type',
		'/user-persona/institute-detail',
		'/user-persona/select-topics',
		'/user-persona/list'
	];
	constructor(private route: Router, private authService: AuthService) {
		this.authService.loggedIn.subscribe({
			next: (res: any) => {
				this.isLoggedIn = res;
			},
			error: (err: any) => {
				if (localStorage.getItem('user_id')) {
					this.isLoggedIn = true;
				} else {
					this.isLoggedIn = false;
				}
			},
		});

		if (localStorage.getItem('user_id')) {
			this.isLoggedIn = true;
		}
	}

	checkNavigation() {
		this.route.events.subscribe((event: any) => {
			if (event instanceof NavigationEnd) {
				console.log(
					this.listOfAllowedPath.includes(event.url.split('?')[0]),
					'header'
				);
				if (this.listOfAllowedPath.includes(event.url.split('?')[0])) {
					this.showHeader = true;
				} else {
					this.showHeader = false;
				}
			}
		});
	}

	collapseSideMenu() {
		this.showMenu = !this.showMenu;
	}

	onLogout() {
		this.isLoggedIn = false;
		localStorage.clear();
		this.route.navigateByUrl('/');
	}

	ngOnInit(): void {
		console.log('HeaderComponent');
		this.checkNavigation();
		// this.authService;
	}
}
