import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    constructor(private http: HttpClient) {}

    private formatErrors(error: any) {
        // alertify.error(error?.error?.status_message);
        return throwError(error.error);
    }

    get(path: string, params: HttpParams = new HttpParams(), header?:any): Observable<any> {
        const url = `${environment.api_url}${path}`;
        return this.http
            .get(url, { params:params,headers:header})
            .pipe(catchError(this.formatErrors));
    }

	externalGet(path: string, params: HttpParams = new HttpParams()): Observable<any> {
        const url = `${environment.api_url}${path}`;
        return this.http
            .get(path, { params })
            .pipe(catchError(this.formatErrors));
    }

    put(path: string, body: any = {}): Observable<any> {
        const url = `${environment.api_url}${path}`;
        return this.http.put(url, body).pipe(catchError(this.formatErrors));
    }

    post(path: string, body: any = {}): Observable<any> {
        const url = `${environment.api_url}${path}`;
        return this.http.post(url, body).pipe(catchError(this.formatErrors));
    }
}
