import { Injectable } from '@angular/core';
import { ApiService } from '../../../../shared/services/api.service';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';
import { API_PATH } from '../../../../../environments/api-path';
import { HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	 loggedIn = new BehaviorSubject<boolean>(false);
	isLoggedIn$ = this.loggedIn.asObservable();
	constructor(private apiService: ApiService) {}

	login(userData: Object): Observable<any> {
		return this.apiService.post(API_PATH.LOGIN, userData).pipe(
			map((data) => {
				if (data.status_code === -1) {
					throw data;
				} else {
					return data.results;
				}
			})
		);
	}


	getDetails(token:any){
		let headers = new HttpHeaders({
			'Authorization':token
		});
		// headers.set('Authorization',token);
		 console.log(headers,token,"getDetails");
		return this.apiService.get(API_PATH.AUTH_VALIDATION,new HttpParams(),headers).pipe(
			map((data) => {
				if (data.status_code === -1) {
					throw data;
				} else {
					return data.results;
				}
			})
		);
	}
}
