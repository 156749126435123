export const API_PATH = {
	LIST_USER_TYPE: 'api/v1/pam/search/static-content/?content_type=PLATFORM_TYPE',
	USER_TYPE: 'api/v1/pam/usage-type/',
	LOGIN: 'api/v1/pam/auth-login/',
	USER_CHOICE: 'api/v1/pam/first-selection/',
	ADD_COLLEGE: 'api/v1/pam/college/add/',
	COLLEGE_LIST: 'api/v1/pam/search/filter-college/list/',
	SCHOOL_LIST: 'api/v1/pam/search/filter-school/list/',
	SUBJECT_LIST: 'api/v1/pam/search/subject/list/',
	ADD_SUBJECTS: 'api/v1/pam/subject/add/',
	CHAT: 'api/v1/pam/converse/manage-thread/',
	TRANSLATE_TEXT: 'api/v1/translation/translate-text/',
	TEXT_TO_SPEECH: 'api/v1/process-speech/text-to-speech/',
	STANDARD_LIST: 'api/v1/pam/search/filter-standard/list/',
	BOARD_LIST: 'api/v1/pam/search/filter-board/list/',
	ADD_SCHOOL: 'api/v1/pam/school/add/',
	SUBSCRIBED_SUBJECTS: 'api/v1/pam/subscribed-subjects/',
	THREADS: 'api/v1/pam/converse/manage-thread',
	ADD_WORKING_PROFESSIONAL: 'api/v1/pam/work-profile/add/',
	GET_STREAM:'api/v1/pam/search/filter-stream/list/',
	DEGREE_LIST:'api/v1/pam/search/filter-degree/list',
	THREAD_HISTORY: 'api/v1/pam/converse/thread-history/',
	GENERATE_QUESTION:'api/v1/pam/converse/topic-question/',
	GET_TOPICS:'api/v1/pam/converse/topics',
	ANALYSIS_ANSWER:'api/v1/pam/converse/analyze-question/',
	ACTIVE_PERSONA:'api/v1/pam/persona/',
	SAVE_ANSWER:'api/v1/pam/converse/answer-question/',
	AUDIO_PROCESS:'api/v1/process-speech/speech-to-text/',
	PERSONA_LIST:'api/v1/pam/my-personas/',
	AUTH_VALIDATION:'api/v1/pam/auth-validate/',
	CREATE_PERSONA:'api/v1/pam/create-persona/',
	UPDATE_PERSONA:'api/v1/pam/persona/',
	SET_LANGUAGE: 'api/v1/pam/set-language/'
};
